import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';

const AddHabit = ({ onHabitAdded }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosInstance.get(
          '/category/getAllCategories',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        );
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setMessage('Error fetching categories');
      }
    };

    fetchCategories();
  }, []);

  const handleAddHabit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId'); // Ensure userId is obtained correctly
      const response = await axiosInstance.post(
        '/habits/addHabit',
        { name, description, categoryName, userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setMessage('Habit added successfully');
      onHabitAdded(); // Call the function to refetch habits
      setName('');
      setDescription('');
      setCategoryName('');
    } catch (error) {
      console.error('Error adding habit:', error);
      setMessage(error.response?.data?.message || 'Error occurred while adding habit');
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md mb-8">
      <h3 className="text-xl font-semibold mb-4">Add New Habit</h3>
      <form onSubmit={handleAddHabit}>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Description:</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Category:</label>
          <select
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          >
            <option value="" disabled>Select a category</option>
            {categories.map((category) => (
              <option key={category._id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300">
          Add Habit
        </button>
      </form>
      {message && <p className="text-green-500 mt-4">{message}</p>}
    </div>
  );
};

export default AddHabit;
