import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import {SignIn, SignUp, Home, Main} from "./pages";

const App = () => {
  const isAuthenticated = () => !!localStorage.getItem('token');

  return (
    <Router>
      
      <Routes>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route
          path="/home"
          element={
            isAuthenticated() ? <Home /> : <Navigate to="/signin" />
          }
        />
        <Route path="*" element={<Navigate to="/signin" />} />
      </Routes>
    </Router>
  );
};

export default App;
