import React, { useState } from 'react';
import axiosInstance from '../axiosConfig';

const AddCategory = ({ onCategoryAdded }) => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const handleAddCategory = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.post(
        '/category/addCategory', // Correct endpoint
        { name },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setMessage('Category added successfully');
      onCategoryAdded(response.data);
      setName('');
    } catch (error) {
      console.error('Error adding category:', error);
      setMessage(error.response?.data?.message || 'Error occurred while adding category');
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Add New Category</h3>
      <form onSubmit={handleAddCategory}>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300">
          Add Category
        </button>
      </form>
      {message && <p className="text-green-500 mt-4">{message}</p>}
    </div>
  );
};

export default AddCategory;
