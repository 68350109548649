import React, { useState } from 'react';
import axiosInstance from '../axiosConfig';

const HabitTable = ({ habits, setHabits }) => {
  const handleAddRow = async () => {
    try {
      const token = localStorage.getItem('token');
      const newStoric = { date: new Date().toISOString(), checked: false };

      console.log("Adding new storic entry:", newStoric);

      const updatedHabits = [...habits];

      // Create new storic entry for each habit
      for (const habit of updatedHabits) {
        console.log(`Adding storic to habit ${habit._id}`);
        await axiosInstance.post(
          `/habits/addStoric/${habit._id}`,
          newStoric,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        );

        // Update the local state of habits
        habit.storic.push(newStoric);
      }

      // Update the habits state with the new storic entries
      setHabits(updatedHabits);

    } catch (error) {
      console.error('Error adding storic entry:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US').format(date);
  };

  const maxStoricLength = Math.max(...habits.map(habit => habit.storic.length), 0);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Date</th>
            {habits.map((habit) => (
              <th key={habit._id} className="py-2 px-4 border-b">{habit.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: maxStoricLength }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              <td className="py-2 px-4 border-b">
                {habits[0]?.storic[rowIndex]?.date && formatDate(habits[0].storic[rowIndex].date)}
              </td>
              {habits.map((habit) => (
                <td key={habit._id} className="py-2 px-4 border-b text-center">
                  <input
                    type="checkbox"
                    checked={habit.storic[rowIndex]?.checked || false}
                    readOnly
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <button
        onClick={handleAddRow}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Add Row
      </button>
    </div>
  );
};

export default HabitTable;
