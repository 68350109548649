import React, { useEffect, useState } from "react";
import axiosInstance from '../axiosConfig';
import { useNavigate } from "react-router-dom";

import AddHabit from "../components/AddHabit";
import AddCategory from "../components/AddCategory";
import HabitTable from "../components/HabitTable";

const Home = () => {
  const [habits, setHabits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');

  const fetchHabits = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axiosInstance.get(
        `/habits/getAllHabits/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setHabits(response.data);
    } catch (error) {
      console.error("Error fetching habits:", error);
      setMessage("Error fetching habits");
    }
  };

  useEffect(() => {
    fetchHabits();
  }, [userId]);

  const fetchCategories = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axiosInstance.get(
        "/category/getAllCategories",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setMessage("Error fetching categories");
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleHabitAdded = () => {
    fetchHabits(); // Refetch habits after a new habit is added
  };

  const handleCategoryAdded = (newCategory) => {
    setCategories((prevCategories) => [...prevCategories, newCategory]);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/signin");
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Home Page</h2>
        <button
          onClick={handleLogout}
          className="bg-red-500 text-white px-4 py-2 rounded"
        >
          Log Out
        </button>
      </div>
      <p className="mb-4">Welcome to the home page!</p>
      {message && <p className="text-red-500 mb-4">{message}</p>}
      <div className="mb-8">
        <AddHabit onHabitAdded={handleHabitAdded} />
      </div>
      <div className="mb-8">
        <AddCategory onCategoryAdded={handleCategoryAdded} />
      </div>
      <h3 className="text-xl font-semibold mb-2">Habits</h3>
      <HabitTable habits={habits} setHabits={setHabits} />
      <h3 className="text-xl font-semibold mb-2 mt-8">Categories</h3>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Name</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category._id}>
              <td className="py-2 px-4 border-b">{category.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Home;
